<template>
<div class="client-container" :style="bg" ref="client-container">
    <el-row >
        <el-col :span="24" >
            <div class="top-bar">
                <el-row>
                    <el-col :span="6" :offset="9">
                        <div class="title-bar">
                            阴极板全生命管理系统
                        </div>
                    </el-col>



                </el-row>

            </div>
        </el-col>
    </el-row>
    <el-row >
        <el-col :span="24" >
            <!--              parentmsg（必填）为输入参数；inputChange（选填）为输入框的钩子函数-->
            <el-row >
                <el-col :span="8" >
                    <clienttable :parentmsg="clientMsgArr[0]" @inputChange="" />
                </el-col>
                <el-col :span="8" >
                    <clienttable :parentmsg="clientMsgArr[1]" @inputChange="" />
                </el-col>
                <!--      parentmsg（必填）为输入参数；loadUl（当类型为ul时，必填）为ul滚动加载时的钩子函数-->
                <el-col :span="8" >
                    <clienttable :parentmsg="clientMsgArr[2]" @loadUl="yjbLoad" />
                </el-col>

            </el-row>
    <!--        <el-row type="flex" justify="center" align="middle">-->
            <el-row>
                <el-col :span="8" >
                    <tabstable :parentmsg="clientMsgArr[3]" @loadUl="dynamic" @inputChange="dynamic" />
                </el-col>
                <el-col :span="8" >
                    <tabstable :parentmsg="clientMsgArr[4]" @loadUl="" />
                </el-col>
                <el-col :span="8" >
                    <clienttable :parentmsg="clientMsgArr[5]" @loadUl="yjbMaintainLoad" />
                </el-col>
            </el-row>
        </el-col>
    </el-row>
    <div class="fullscreen" @click="switchFullscreen(`client-container`)">
    </div>
</div>
</template>

<script>
import HomePage from "../Home.vue"
import Clienttable from "@/components/clientTable.vue";
import Tabstable from "@/components/tabsTable.vue";
import { fGetWindowHeight} from "@/utils/utilsset";
import { sanyouAxios} from "@/api";
export default {
name: "Client",
    components:{
    HomePage,
        Tabstable,
        Clienttable,
    },
data(){
  return{
      clientMsgArr:[
          //阴极板管理
          {
              title:'阴极板管理',//标题
              tableNum:1,//数据表的数量（clientTable内无用）
              tableType:'table',//数据表的类型:table,chart,ul
              inputTxt:'周期时间',//输入框内容(如果不需要输入框，就置空)（需要和inputChange配合使用）
              inputData:'',//输入框值(如果不需要输入框，就置空)（需要和inputChange配合使用）
              isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
              //数据表参数
              tablePara:{
                  //本来想实现父组件动态控制子组件的高度，但目前无法实现
                  table1Height:240,
                  //element ui的table默认是纵向加载数据的，
                  // 为了让数据横向加载，这里采用别的方法来排列数据
                  //1号表格的数据
                  tableData1: [
                      [' ','三门三友',],
                      ['总板数','0',],
                      ['在线板数','0',],
                      ['维修板数','0',],
                      ['备用板数','0',],
                      ['报废板数','0',],
                      ['新增板数','0',],
                      ['无编码板数','0',],
                  ],
              },
          },
          //机组剥片速度
          {
              title:'机组剥片速度',//标题
              tableNum:1,//数据表的数量（clientTable内无用）
              tableType:'chart',//数据表的类型:table,chart,ul
              inputTxt:'',//输入框内容(如果不需要输入框，就置空)（需要和inputChange配合使用）
              inputData:'',//输入框值(如果不需要输入框，就置空)（需要和inputChange配合使用）
              isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
              //数据表参数
              tablePara:{
                  //控制echart的css样式，主要是是为了控制高度
                  chartStyle:{'height': '30vh','background': 'rgba(255,255,255,0.05)'},
                  // xAxis: {
                  //     data: ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00","10:00", "11:00",
                  //         "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00",
                  //         "20:00", "21:00", "22:00", "23:00", ]
                  // },
                  //数据集    https://echarts.apache.org/handbook/zh/concepts/dataset
                  dataSet:[
                      ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00','7:00',
                          '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
                          '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',],
                      ['', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
                  ],
                  //展示的样式   type: 'bar'  'line'
                  series: [
                   /*   { type: 'line',seriesLayoutBy: 'row' ,

                          color: "#d97559",
                          areaStyle: {

                          }

                      },*/
                      //需求变更，要求采用滚动条
                      {
                          color: {
                              type: 'linear',
                              x: 0,
                              y: 0,
                              x2: 0,
                              y2: 1,
                              colorStops: [{
                                  offset: 0, color: '#d97559'    // 0% 处的颜色
                              }, {
                                  offset: 1, color: 'rgba(255,97,0, 0.3)' //   100% 处的颜色
                              }],
                              // global: false // 缺省为 false
                          },

                          type: 'line',
                          seriesLayoutBy: 'row',//横向读取数据集
                          barWidth: '30%',
                          itemStyle: {
                        /*      normal: {
                                  color: this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                      offset: 0,
                                      color: "#1268f3" // 0% 处的颜色
                                  }, {
                                      offset: 0.6,
                                      color: "#08a4fa" // 60% 处的颜色
                                  }, {
                                      offset: 1,
                                      color: "#01ccfe" // 100% 处的颜色
                                  }], false)
                              },
*/


                              barBorderRadius: [2, 2, 0, 0],


                                  shadowColor: 'rgba(0,160,221,1)',
                                  shadowBlur: 4,

                          },


                          // label: {
                          //     normal: {
                          //         show: true,
                          //         lineHeight: 10,
                          //         formatter: '{c}',
                          //         position: 'top',
                          //         textStyle: {
                          //             color: '#00D6F9',
                          //             fontSize: 12
                          //         }
                          //
                          //     }
                          // }
                      }
                  ],
                  // dataZoom 组件 用于区域缩放
                  dataZoom: [
                      {
                          type: 'slider',
                          show: true, // 设置 dataZoom-inside 组件控制的 x轴
                          rangeMode: ['value', 'percent'],
                          // 数据窗口范围的起始和结束的具体值  范围: 0:00 ~ 23:00
                          //start: 0,
                          startValue:'6:00',
                          //end: 100,
                          endValue:'23:00',
                          xAxisIndex: [0],
                          filterMode: 'empty',
                          bottom: -25
                      }
                  ],
              },},
          //阴极板全生命周期管理
          {
              title:'阴极板全生命周期管理',//标题
              tableNum:1,//数据表的数量（clientTable内无用）
              tableType:'ul',//数据表的类型:table,chart,ul
              inputTxt:'',//输入框内容(如果不需要输入框，就置空)（需要和inputChange配合使用）
              inputData:'',//输入框值(如果不需要输入框，就置空)（需要和inputChange配合使用）
              isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
              //数据表参数
              tablePara:{
                  //ul的样式控制
                  ulStyle:{
                      overflow:'auto',
                      height: '215px',//必须用px，vh会报错
                      color:'#ffffff',
                      fontSize:'12px',
                  },
                  // 为了让数据横向加载，这里采用别的方法来排列数据
                  //1号表格的数据
                  tableData1: [
                      ['阴极板编码','板面形变MAX','A面OK/NG','B面OK/NG',],
                      /*['xxxxxx','25','OK','NG',],
                      ['xxxxxx','23','NG','NG',],
                      ['xxxxxx','21','OK','OK',],
                      ['xxxxxx','19','NG','OK',],
                      ['xxxxxx','19','NG','NG',],
                      ['xxxxxx','17','OK','OK',],
                      ['xxxxxx','16','OK','NG',],*/

                  ],
              },
          },
          //动态检测
          {
              title:['动态检测','建议维修的阴极板'],//标题
              tableNum:2,//数据表的数量（tabsTable内无用）
              tableType:'ul',//数据表的类型:table,chart
              inputTxt:'筛选值',//输入框内容(如果不需要输入框，就置空)（需要和inputChange配合使用）
              inputData:'5',//输入框值(如果不需要输入框，就置空)（需要和inputChange配合使用）
              isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
              //数据表参数
              tablePara:{
                  //控制echart的css样式，主要是是为了控制高度
                  chartStyle:{'height': '30vh','background': 'rgba(255,255,255,0.05)'},
                  //ul的样式控制
                  ulStyle:{
                      overflow:'auto',
                      height: '215px',//必须用px，vh会报错
                      color:'#ffffff',
                      fontSize:'12px',
                  },
                  //数据集    https://echarts.apache.org/handbook/zh/concepts/dataset
                  dataSet:[
                      ['','健康', '亚健康', '不健康', ],
                      ["奥炉车间",0, 0, 0, ],
                  ],
                  //展示的样式   type: 'bar'  'line'
                  series: [
                      { type: 'pie', seriesLayoutBy: 'row',radius: ['40%', '70%'],
                      label: {
                          normal: {
                              position: 'center',
                              show: false
                          }
                      },
                          emphasis: {
                              label: {
                                  show: true,
                                  fontSize: 20,
                                  fontWeight: 'bold'
                              }
                          },
                          itemStyle: {
                              normal: {
                                  color: function (colors) {
                                      let colorList = [
                                          // '#fc8251',
                                          '#4CAE85',
                                          '#FFAD00',
                                          '#FF6356',

                                      ];
                                      return colorList[colors.dataIndex];
                                  }
                              },
                          }
                      },//横向读取数据集


                  ],
                  //本来想实现父组件动态控制子组件的高度，但目前无法实现
                  table1Height:8,
                  //element ui的table默认是纵向加载数据的，
                  // 为了让数据横向加载，这里采用别的方法来排列数据
                  //1号表格的数据
                  tableData1:[
                      ['序号','阴极板编码','变形程度',],
                      ['1','xxxxxx','25',],
                      ['2','xxxxxx','23',],
                      ['3','xxxxxx','22',],
                      ['4','xxxxxx','19',],
                      ['5','xxxxxx','17',],
                  ],
              },},
          //阴极铜表面缺陷检测
          {
              title:['阴极铜表面缺陷检测','不合格类型'],//标题
              tableNum:2,//数据表的数量（tabsTable内无用）
              tableType:'ul',//数据表的类型:table,chart
              inputTxt:'',//输入框内容(如果不需要输入框，就置空)（需要和inputChange配合使用）
              inputData:'',//输入框值(如果不需要输入框，就置空)（需要和inputChange配合使用）
              isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
              //数据表参数
              tablePara:{
                  //控制echart的css样式，主要是是为了控制高度
                  chartStyle:{'height': '30vh','background': 'rgba(255,255,255,0.05)'},
                  //ul的样式控制
                  ulStyle:{
                      overflow:'auto',
                      height: '215px',//必须用px，vh会报错
                      color:'#ffffff',
                      fontSize:'12px',
                  },
                  //数据集    https://echarts.apache.org/handbook/zh/concepts/dataset
                  dataSet:[
                      ['','不合格板数', '合格板数', ],
                      ['',0, 0,],
                  ],
                  //展示的样式   type: 'bar'  'line'
                  series: [
                      { type: 'pie',
                        seriesLayoutBy: 'row',
                          radius: ['40%', '70%'],
                          label: {
                              show: false,
                              position: 'center'
                          },
                          emphasis: {
                              label: {
                                  show: true,
                                  fontSize: 20,
                                  fontWeight: 'bold'
                              }
                          },
                          itemStyle: {
                              normal: {
                                  color: function (colors) {
                                      let colorList = [
                                              // '#fc8251',
                                              // '#5470c6',
                                              // '#91cd77',
                                              // '#ef6567',
                                          '#FF6356',
                                          '#4CAE85'
                                      ];
                                      return colorList[colors.dataIndex];
                                  }
                              },
                          }

                      },//横向读取数据集

                  ],
                  // 为了让数据横向加载，这里采用别的方法来排列数据
                  //1号表格的数据
                  tableData1:[
                      ['粒子类型','数量',],
                      ['单面下沿密','25',],
                      ['无规律圆头','23',],
                      ['无规律开花','22',],
                      ['上沿漂浮粒子','19',],
                      ['其他形态粒子','17',],
                      ['烧板','17',],
                  ],
              },},
          //阴极板维修信息
          {
              title:'阴极板维修信息 ',//标题
              tableNum:1,//数据表的数量（clientTable内无用）
              tableType:'ul',//数据表的类型:table,chart,ul
              inputTxt:'',//输入框内容(如果不需要输入框，就置空)（需要和inputChange配合使用）
              inputData:'',//输入框值(如果不需要输入框，就置空)（需要和inputChange配合使用）
              isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
              //数据表参数
              tablePara:{
                  //ul的样式控制
                  ulStyle:{
                      overflow:'auto',
                      height: '215px',//必须用px，vh会报错
                      color:'#ffffff',
                      fontSize:'12px',
                  },
                  // 为了让数据横向加载，这里采用别的方法来排列数据
                  //1号表格的数据
                  tableData1: [
                      ['排序','检测时间','阴极板编码','短路形态',],
                      ['1','2022-09-16 20:45:03','xxxxxx','铜皮',],
                      ['2','2022-09-16 20:45:03','xxxxxx','开花',],
                      ['3','2022-09-16 20:45:03','xxxxxx','未处理',],
                      ['4','2022-09-16 20:45:03','xxxxxx','铜皮 圆头',],
                      ['5','2022-09-16 20:45:03','xxxxxx','铜皮 开花',],
                      ['6','2022-09-16 20:45:03','xxxxxx','未处理',],
                      ['7','2022-09-16 20:45:03','xxxxxx','未处理',],

                  ],
                  dataInfo: {
                      dataCode: 'MAINTAIN_TYPE',  //数据字典类型
                      colIndex:2,   //需要替换的列的index
                  },
              },
          },
      ],
      bg: {
          backgroundRepeat:'no-repeat',
          backgroundSize:'100%',
      },
      isFullScreen:false,
      //动态检查相关
      needRepairThreshold:'',
      dynamicCount:0,
      dynamicPage:1,
      dynamicPageSize:10,
      dynamicTotal:-1,
      //阴极板相关
      yjbPage:1,
      yjbTotal:-1,
      yjbPageSize:10,
      //阴极板维修相关
      yjbMaintainPage:1,
      yjbMaintainTotal:-1,
      yjbMaintainPageSize:10,
  }
},
    created() {
        this.currentUserId=localStorage.getItem('id');
        this.needRepairThreshold=localStorage.getItem("needRepairThreshold")
        this.clientMsgArr[3].inputData=this.needRepairThreshold||this.clientMsgArr[3].inputData

        //根据当前时间确定滚动条位置
        let date=new Date();
        let hour=date.getHours();
        //let hour=18;
        // if(hour>17) {hour++;}
        // this.clientMsgArr[1].tablePara.dataZoom[0].start=98;
        this.clientMsgArr[1].tablePara.dataZoom[0].endValue=hour+':00';


        // this.currentUserId="2211289PD9YK15KP";
        this.bg.backgroundImage = 'url(' + require('@/assets/imgs/bgClient.png') + ')';
        this.requestAll();

        this.ulResize(140);
    },
    mounted() {

    },
    methods:{
        //请求数据
        requestAll(){
            //this.yjbLoad();
            this.bhgLoad();
            this.speed();
            this.dynamic();
            sanyouAxios({
                method:"GET",
                url:'/product/getClientProductCounts?userId='+this.currentUserId,
            }).then(res=>{
                if (res.data.status===200){

                    let data=res.data.data;
                    let tableData=[
                        ['',],
                        ['总板数',],
                        ['在线板数',],
                        ['维修板数',],
                        ['备用板数',],
                        ['报废板数',],
                        ['新增板数',],
                        ['无编码板数',],

                    ];

                    tableData[0].push(data['subFactoryName'])
                    tableData[1].push(data['sum']);
                    tableData[2].push(data['online']);
                    tableData[3].push(data['maintain']);
                    tableData[4].push(data['offline']);
                    tableData[5].push(data['scrap']);
                    tableData[6].push(data['newAdd']);
                    tableData[7].push(data['uncodeNum']);
                    // console.log(tableData[1])
                    //console.log(data)
                    this.clientMsgArr[0].tablePara.tableData1=tableData;
                    this.clientMsgArr[0].inputData=data['cycle'];
                    this.$set(this.clientMsgArr[0],"isRefreshed",!this.clientMsgArr[0].isRefreshed);
                }else {
                    //console.log(this.currentUserId)
                    this.$message({
                        type:'warning',
                        duration:200,
                        message:res.data.msg
                });
                }
            });

        },
        //阴极板全生命周期管理-ul动态加载
        yjbLoad(){
            if(this.yjbTotal>=0&&this.yjbPage>this.yjbTotal)//超过分页的total，就不请求
                return;

            sanyouAxios({
                method:"POST",
                url:"/client/lifeCycleManagement?userId="+this.currentUserId+'&&page='+this.yjbPage+'&&pageSize='+this.yjbPageSize
            }).then(res=>{
                if (res.data.status===200){
                    let data=res.data.data.rows
                    this.yjbTotal=res.data.data.total;

                    // console.log(data)
                    if(this.yjbPage===1){
                        this.clientMsgArr[2].tablePara.tableData1=[
                            ['阴极板编码','板面形变MAX','A面OK/NG','B面OK/NG',],
                        ]
                    }

                    for (let i in data){
                        let rowData=[data[i]['plateno'],data[i]['max'],data[i]['aArea'],data[i]['bArea']];
                        // console.log(rowData)
                        this.clientMsgArr[2].tablePara.tableData1.push(rowData)

                    }
                    // console.log(tableData)
                    //this.clientMsgArr[2].tablePara.tableData1=tableData;
                    this.$set(this.clientMsgArr[2],'isRefreshed',!this.clientMsgArr[2].isRefreshed);
                    this.yjbPage++;
                }else {
                    this.$message({
                        type:'warning',
                        duration:200,
                        message:res.data.msg})
                }
            })
            //由于接口没写好，所以这里只是进行普通的拼接
          /*  this.clientMsgArr[2].tablePara.tableData1.push(['070012','12','NG','OK',]);
            this.$set(this.clientMsgArr[2],'isRefreshed',!this.clientMsgArr[2].isRefreshed);*!/*/
        },
        //阴极板维修信息-ul动态加载
        yjbMaintainLoad(){
            if(this.yjbMaintainTotal>=0&&this.yjbMaintainPage>this.yjbMaintainTotal)//超过分页的total，就不请求
                return;

            sanyouAxios({
                method:"GET",///client/getProductMaintainData?userId=22092271S5GFY2RP&page=1&pageSize=10
                url:"/client/getProductMaintainData?userId="+this.currentUserId+'&&page='+this.yjbMaintainPage+'&&pageSize='+this.yjbMaintainPageSize
            }).then(res=>{
                if (res.data.status===200){
                    let data=res.data.data.rows
                    this.yjbMaintainTotal=res.data.data.total;

                    // console.log(data)
                    if(this.yjbMaintainPage===1){
                        this.clientMsgArr[5].tablePara.tableData1=[
                            ['阴极板编码','维修时间','维修类型','维修者',],
                        ]
                    }

                    for (let i in data){
                        //这里的maintainType位置修改时，记得修改clientMsgArr[5].tablePara.dataInfo的colIndex
                        let rowData=[data[i]['productCode'],data[i]['maintainTime'],data[i]['maintainType'],data[i]['maintainer']];
                        // console.log(rowData)
                        this.clientMsgArr[5].tablePara.tableData1.push(rowData)

                    }
                    // console.log(tableData)
                    //this.clientMsgArr[5].tablePara.tableData1=tableData;
                    this.$set(this.clientMsgArr[5],'isRefreshed',!this.clientMsgArr[5].isRefreshed);
                    this.yjbMaintainPage++;
                }else {
                    this.$message({
                        type:'warning',
                        duration:200,
                        message:res.data.msg})
                }
            })
        },
        //阴极铜表面缺陷检测
        bhgLoad(){
            sanyouAxios({
                method:"POST",
                url:"/copper/homePageDefect?",
                data:{
                   id:this.currentUserId}

            }).then(res=>{
                if (res.data.status===200){
                    let data=res.data.data;
                    let dataSet=[
                        ['','不合格板数', '合格板数', ],
                        ];
                    let tableData=[
                        ['粒子类型','数量',],
                    ];

                    let type=data.type;
                    for (let [key ,val] of  Object.entries(type)){
                        tableData.push([key,val])

                    }
                    // console.log(tableData)
                    let rowData1=['',data.nGNum,data.oKNum]
                    dataSet.push(rowData1);
                    this.clientMsgArr[4].tablePara.dataSet=dataSet;
                    this.clientMsgArr[4].tablePara.tableData1=tableData;
                    // console.log( this.clientMsgArr[4].tablePara.tableData1)
                    this.$set(this.clientMsgArr[4],'isRefreshed',!this.clientMsgArr[4].isRefreshed);


                }else{

                    this.$message({
                        type:'warning',
                        duration:200,
                        message:res.data.msg})


                }
            })

        },
        //机组剥片速度
        speed(){
            sanyouAxios({
                method:"GET",
                url:"/client/strippingSpeed?userId="+this.currentUserId,
            }).then(res=>{
                if (res.data.status===200){
                    let data=res.data.data;

                    let dataSet=[
                        [''],
                        [data['subFactoryName']],

                    ]
                    //console.log(data['subFactoryName']);
                    // dataSet[1].push(data.subFactoryName);

                    let dataSet0=data['strippingSpeedList'];
                    // console.log(dataSet0)
                    for (let i in dataSet0){
                        //dataSet[0].push(dataSet0[i]["time"])
                        dataSet[1].push(dataSet0[i]["speed"])
                    }
                    //console.log(dataSet)
                    dataSet[0]=this.clientMsgArr[1].tablePara.dataSet[0]
                    this.clientMsgArr[1].tablePara.dataSet=dataSet
                    // 根据时间
                    let date=new Date();
                    let hour=date.getHours();
                    this.clientMsgArr[1].tablePara.dataZoom[0].endValue=hour+':00';

                    //console.log(this.clientMsgArr[1].tablePara)
                    this.$set(this.clientMsgArr[1],'isRefreshed',!this.clientMsgArr[1].isRefreshed);

                }else {
                    this.$message({
                        type:'warning',
                        duration:200,
                        message:res.data.msg})
                }
            })

        },
        //动态检测
        dynamic(input){
            //console.log(input);
            //console.log('dynamic')
            //debugger
            this.needRepairThreshold=input||this.clientMsgArr[3].inputData;
            if(this.dynamicTotal>=0&&this.dynamicPage>this.dynamicTotal&&!input)//超过分页的total，就不请求
                return;
            /*if(!input)//避免陷入请求循环
                return;*/
            else{
                this.clientMsgArr[3].inputData=this.needRepairThreshold;
                this.dynamicCount++;
            }
            localStorage.setItem('needRepairThreshold',this.needRepairThreshold);
            //console.log(this.needRepairThreshold);
            sanyouAxios({
                method:"GET",
                url:"/client/dynamicDetect?userId="+this.currentUserId+"&&needRepairThreshold="+this.needRepairThreshold
                    +'&page='+this.dynamicPage+'&size='+this.dynamicPageSize,
            }).then(res=>{
                if (res.data.status===200){
                    let data=res.data.data;
                    this.dynamicTotal=data['total'];
                    if(this.dynamicPage===1){
                        this.clientMsgArr[3].tablePara.dataSet=[
                            ['','健康', '亚健康', '不健康', ],
                        ];
                        this.clientMsgArr[3].tablePara.tableData1=[
                            ['序号','阴极板编码','变形程度',],
                        ];
                    }
                    let rowData1=['',data['healthNum'],data['subHealthNum'],data['unHealthNum'],];
                    this.clientMsgArr[3].tablePara.dataSet.push(rowData1);
                    let needRepairList=data['needRepairPlates'];
                    for (let i in needRepairList){
                        let rowData2=[needRepairList[i]["serialNum"],needRepairList[i]["plateNo"],needRepairList[i]["shapeChange"]];
                        //需求：保留一位小数
                        //rowData2[2]=((Math.floor(Math.random() * (250 - 150)) + 150)/10).toFixed(1);
                        rowData2[2]=Number(rowData2[2]).toFixed(1)
                        this.clientMsgArr[3].tablePara.tableData1.push(rowData2);
                    }
                    // console.log( this.clientMsgArr[4].tablePara.tableData1)
                    this.$set(this.clientMsgArr[3],'isRefreshed',!this.clientMsgArr[3].isRefreshed);
                    this.dynamicPage++;
                }else {
                    this.$message({
                        type:'warning',
                        duration:200,
                        message:res.data.msg})
                }
            })
        },
        //切换全屏
        switchFullscreen(x){
            if(this.isFullScreen){
                this.exitFullscreen();
            }
            else {
                this.fullScreen(x);
            }
            this.isFullScreen=!this.isFullScreen;
        },
        //全屏
        fullScreen(x) {
            let element = this.$refs[x];
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            }
            this.ulResize(0);
        },
        //退出全屏
        exitFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
            this.ulResize(140);
        },
        //重新计算ul的高度
        ulResize(offset){
            //let height=getStyle(this.$refs["client-container"],'height');
            let height=(fGetWindowHeight()-offset)*0.3;
            //console.log(height);
            this.clientMsgArr[2].tablePara.ulStyle.height=height+'px';
            this.clientMsgArr[3].tablePara.ulStyle.height=height+'px';
            this.clientMsgArr[4].tablePara.ulStyle.height=height+'px';
            this.clientMsgArr[5].tablePara.ulStyle.height=height+'px';
        }
    }
}
</script>

<style scoped>
.client-container{
    height: 100%;
}
</style>
