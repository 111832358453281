<template>
    <div class="data-table">
        <el-row >
            <el-col :span="24" :offset="0" >
                <div class="inner-title-bar-bg">
                    <div class="inner-title-bar" >
                        {{ parentmsg.title }}
                    </div>
                </div>

            </el-col>
        </el-row>
        <!--                table类型-->
        <el-row v-if="this.parentmsg['tableType']==='table'">
            <el-col :span="24" :offset="0">
                <div >
<!--                    v-fit-columns-->
                    <el-table
                        :data="tableData1"
                        :header-row-style="{height:'30px'}"
                        :header-cell-style="{background:'rgba(0, 172, 255, 30%)',padding:'0px',color:'#3ddeff',fontSize:'12px'}"
                        :row-style="{height:'30px'}"
                        :cell-style="{background:'rgba(0, 172, 255, 10%)',padding:'0px',color:'#3ddeff',fontSize:'12px'}"
                        :span-method="objectSpanMethod"
                        height="calc(24.8vh)"
                        border
                        style="width: 100%;">
<!--                        <el-table-column
                            prop="title"
                            :label="parentmsg.tablePara.tableData1[0][0]"
                            :width="parentmsg.tablePara.tableData1.width||80">
                        </el-table-column>-->
                        <el-table-column
                            v-for="(item,index) in headerDetail1"
                            :prop="item.key"
                            :key="index"
                            :label="item.name"
                            width="130"
                            >
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <!--                chart类型-->
        <el-row v-if="this.parentmsg['tableType']==='chart'">
            <el-col :span="24" :offset="0">
                <div ref="customChart" :style="chartStyle">
                </div>
            </el-col>
        </el-row>
        <!--                table类型-->
        <el-row v-if="this.parentmsg['tableNum']===2">
            <el-col :span="24" :offset="0" >
                <div class="data-table-2">
                    <el-table
                        :data="tableData2"
                        :header-row-style="{height:'30px'}"
                        :header-cell-style="{background:'rgba(0, 172, 255, 30%)',padding:'0px',color:'#3ddeff',fontSize:'12px'}"
                        :row-style="{height:'30px'}"
                        :cell-style="{background:'rgba(0, 172, 255, 10%)',padding:'0px',color:'#3ddeff',fontSize:'12px'}"
                        height="100%"
                        border
                        style="width: 100%;min-height: 200px">
<!--                        <el-table-column
                            prop="title"
                            :label="parentmsg.tablePara.tableData2[0][0]"
                            :width="parentmsg.tablePara.tableData2.width||80">
                        </el-table-column>-->
                        <el-table-column
                            v-for="(item,index) in headerDetail2"
                            :prop="item.key"
                            :key="index"
                            :label="item.name"
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "datatable",
    props:{
        //这是父组件传入的内容
        parentmsg: {
            type: Object
        },
    },
    data() {
        return {
            tableData1:[],//渲染表格数据
            headerDetail1: [],//渲染1号表头
            tableData2:[],//渲染表格数据
            headerDetail2:[],//渲染2号表头
            table1Style:{},//用于控制table1的css，暂时没用
            table2Style:{//用于控制table2的样式
                height:'200'
            },
            chartStyle:{'height': '12vw','background': 'rgba(14,194,255,0.15)'},//用于控制e-chart的css
            customChart:null,//页面上的chart的对象
            //用于设置echart的参数
            chartOption:{
                tooltip: {},
                //页面的位置布局相关
                grid: {
                    left: '2%',
                    right: '4%',
                    bottom: '6%',
                    top: '18%',
                    containLabel: true
                },
                //图例
                legend: {
                    x: "center",
                    top: 2.5,
                    textStyle: {
                        color: "#3ddeff",
                        fontSize: 12,
                    },
                    itemWidth: 15,
                    itemHeight: 8,
                },
                //数据集，由父组件传入
                dataset: {
                    // 提供一份数据。
                    source: null,
                },
                //x轴相关的样式
                xAxis: {
                    type: 'category',
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.15)',
                        },

                    },
                    axisLabel: {
                        show: true,//隐藏底部时间
                        formatter: '{value}',
                        fontSize: 12,
                        margin: 10,
                        interval: 0,
                        rotate: 90,
                        textStyle: {
                            color: '#3ddeff',
                        },
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                //y轴相关的样式
                yAxis: {
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#3ddeff',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.15)',
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.15)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                },
                //数据集的展示方法，由父组件传入
                series: null,
                // dataZoom 组件 用于区域缩放，由父组件传入
                dataZoom:null,
                /*dataZoom: [
                    {
                        type: 'slider',
                        show: true, // 设置 dataZoom-inside 组件控制的 x轴
                        // 数据窗口范围的起始和结束百分比  范围: 0 ~ 100
                        start: 0,
                        end: 100,
                        xAxisIndex: [0],
                        filterMode: 'empty',
                        bottom: -25
                    }
                ],*/
            },
        };
    },
    created() {
        //echart的样式可由父组件控制
        this.chartStyle=this.parentmsg.tablePara.chartStyle||{'height': '12vw','background': 'rgba(14,194,255,0.15)'};
        if (this.parentmsg.tablePara.xAxis){
            this.chartOption.xAxis=this.parentmsg.tablePara.xAxis;
        }
    },
    mounted() {
        //是否需要展示第一个table
        if(this.parentmsg.tableType==='table') {
            this.initTable(
                this.parentmsg.tablePara.tableData1,
                this.tableData1,
                this.headerDetail1);
        }
        //是否需要展示第二个table
        if(this.parentmsg.tableType==='chart'&& Number(this.parentmsg.tableNum)===2) {
            this.initTable(
                this.parentmsg.tablePara.tableData2,
                this.tableData2,
                this.headerDetail2);
        }
        if(this.parentmsg.tableType==='chart')
        {
            this.initChart();
        }
        // this.table2Style.height=Number.parseInt(document.body.offsetHeight) * 0.32 + 'px'
    },
    methods: {
        //合并行或列
        objectSpanMethod({ row, column, rowIndex, columnIndex }){
            //合并行或列
            if(this.parentmsg.title==='阴极板统计'){
                if (rowIndex  === 4) {
                    if (columnIndex === 0) {
                        //console.log({ row, column, rowIndex, columnIndex });
                        return {
                            rowspan: 1,
                            colspan: this.headerDetail1.length+1,
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            }
        },
        //初始化table
        initTable(input,tableData,headerData){
            //console.log(this.parentmsg.title);
            this.$nextTick(()=>{
                for (let i in input) {

                    let tableDetail = {};
                    for (let j in input[i]){
                        if(Number(i)===0){
                            headerData.push({name:input[i][j],key:'num'+j});
                        } else {
                            tableDetail['num'+j]=input[i][j];
                        }
                    }
                    if(Number(i)===0)
                        continue;
                    tableData.push(tableDetail);
                }
            })
            /*
            console.log('tableData1');
            console.log(JSON.stringify(this.tableData1));
            console.log('headerDetail1');
            console.log(JSON.stringify(this.headerDetail1));*/
        },
        //初始化echart参数
        initChart(){
            let app=this;
            //父组件传入数据
            this.chartOption.dataset.source=this.parentmsg.tablePara.dataSet;
            this.chartOption.series=this.parentmsg.tablePara.series;
            this.chartOption.dataZoom=this.parentmsg.tablePara.dataZoom||null;
            //开始初始化echart
            this.customChart = app.$echarts.init(this.$refs.customChart);
            this.customChart.setOption(this.chartOption);

            window.addEventListener('resize', function () {
                app.customChart.resize();
            })
        },
    },
    watch: {
        //监听父组件的消息
        parentmsg:{
            handler: function (newV, oldV) {
                // oldV  parentmsg旧值
                // newV parentmsg新值
                //console.log(newV);

                //根据异步更新的数据进行更新
                if(this.customChart!=null) {
                    //更新echart
                    this.chartOption.dataset.source=this.parentmsg.tablePara.dataSet;
                    this.chartOption.series=newV.tablePara.series;
                    //console.log(this.parentmsg.tablePara.dataSet);
                    //由于echart抽风，这里需要强制销毁再刷新
                    this.customChart.dispose();
                    this.customChart = this.$echarts.init(this.$refs.customChart);
                    this.customChart.setOption(this.chartOption);

                    if(Number(this.parentmsg.tableNum)===2) {
                        //更新2号table
                        this.tableData2=[];
                        this.headerDetail2=[];
                        // let bodyWrapperDOM=document.getElementsByClassName("data-table-2")[0]
                        //     .getElementsByClassName('el-table__body-wrapper')[0]

                        this.initTable(
                            this.parentmsg.tablePara.tableData2,
                            this.tableData2,
                            this.headerDetail2);
                        // console.log(document.body.offsetHeight)
                        // this.table2Style.height=Number.parseInt(document.body.offsetHeight) * 0.32 + 'px'
                    }
                }
                else if(this.parentmsg.tableType==='table'){
                    //更新1号table
                    this.tableData1=[];
                    this.headerDetail1=[];
                    this.initTable(
                        this.parentmsg.tablePara.tableData1,
                        this.tableData1,
                        this.headerDetail1);
                }
            },
            deep:true,//深度监听
            //immediate:true,//立刻执行
        }
    }
}
</script>

<style scoped>
.inner-title-bar{
    /*margin-top: -1.1vh;*/
    margin-right: 4vw;
    margin-left: 2.5vw;
    margin-bottom: 0.3vh;
    background: linear-gradient(180deg,#34c2c9,#34176d);
    max-width: 15vw;
    height: 25px;
    min-height: 2.5vh;
    text-align: center;
    font-size: 1.8vh;
    color: #E9E7E7;
}
.inner-title-bar-bg{
    /*margin-top: -1.1vh;*/
    height: 35px;
    background: url(../assets/imgs/bg-title.png) no-repeat left top;
}
.data-table-2 {
    height: 30vh;
    min-height: 150px
}
.data-table-2 .el-table .el-table__body-wrapper{
    height: calc(30vh - 30px) !important;
    min-height: 200px
}
</style>
