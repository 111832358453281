<template>
    <div class="client-table">
<!--   标题，不是tab页（tab页切换详见另外的tabsTable） -->
        <el-row v-if="this.parentmsg['title']">
            <el-col :span="9" :offset="0">
                <div class="client-title-bar" >
                    {{ parentmsg['title'] }}
                </div>
            </el-col>
            <!--   输入框-->
            <el-col :span="12" :offset="3" v-if="this.parentmsg['inputTxt']">
                <el-form ref="form" :model="form" label-width="10vw">
                    <el-form-item :label="this.parentmsg['inputTxt']">
                        <el-input v-model="form.inputData" size="mini"
                                  @change="inputChange" :disabled="true"
                                  :placeholder="this.parentmsg['inputTxt']"></el-input>
                    </el-form-item>
                </el-form>

            </el-col>
        </el-row>
<!--   输入框-->
<!--        <el-row v-if="this.parentmsg['inputTxt']">
            <el-col :span="12" :offset="12">
                <el-form ref="form" :model="form" label-width="10vw">
                    <el-form-item :label="this.parentmsg['inputTxt']">
                        <el-input v-model="form.inputData" size="mini"
                                  @change="inputChange"
                                  :placeholder="this.parentmsg['inputTxt']"></el-input>
                    </el-form-item>
                </el-form>

            </el-col>
        </el-row>-->
        <!--          table类型-->
        <el-row v-if="this.parentmsg['tableType']==='table'">
            <el-col :span="24" :offset="0">
                <div >
<!--                    v-fit-columns-->
                    <el-table
                        :data="tableData1"
                        :header-row-style="{height:'30px'}"
                        :header-cell-style="{background:'rgba(255, 255, 255, 0.05)',padding:'0px',color:'rgb(255, 255, 255)',fontSize:'12px'}"
                        :row-style="{height:'30px'}"
                        :cell-style="{background:'rgba(255, 255, 255, 0.05)',padding:'0px',color:'rgb(255, 255, 255)',fontSize:'12px'}"
                        height="calc(30vh)"
                        border
                        style="width: 100%;">
<!--                        <el-table-column
                            prop="title"
                            :label="parentmsg.tablePara.tableData1[0][0]"
                            :width="parentmsg.tablePara.tableData1.width||80">
                        </el-table-column>-->
                        <el-table-column
                            v-for="(item,index) in headerDetail1"
                            :prop="item.key"
                            :key="index"
                            :label="item.name"

                            >
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <!--           chart类型-->
        <el-row v-if="this.parentmsg['tableType']==='chart'">
            <el-col :span="24" :offset="0">
                <div ref="customChart" :style="chartStyle">
                </div>
            </el-col>
        </el-row>
        <!--           ul类型-->
        <el-row v-if="this.parentmsg['tableType']==='ul'">
            <el-col :span="24" :offset="0">
<!--               出现加载动画的版本 -->
<!--                <div class="infinite-list-wrapper" :style="ulStyle">
                    <ul class="list"
                        v-infinite-scroll="loadUl"
                        infinite-scroll-disabled="ulDisabled">
                        <li v-for="item in ulData" class="infinite-list-item">
                            <el-row class="client-ul-row">
                                <el-col class="client-ul-col" :span="(24/(item.length))" v-for="x in item">
                                    {{ x }}
                                </el-col>
                            </el-row>
                        </li>
                    </ul>
                    <p v-if="ulLoading">加载中...</p>
                    <p v-if="ulNoMore">没有更多了</p>
                </div>-->
<!--             无加载动画的版本   -->
                <ul class="infinite-list" v-infinite-scroll="loadUl" infinite-scroll-distance="15" infinite-scroll-delay="500" :style="ulStyle">
                    <li v-for="item in ulData" class="infinite-list-item" :key="JSON.stringify(item)">
                        <el-row class="client-ul-row">
                            <el-col class="client-ul-col" :span="(24/(item.length))" v-for="x in item" :key="JSON.stringify(x)">
                                {{ x }}
                            </el-col>
                        </el-row>
                    </li>
                </ul>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {fDataInfoFormatter, fGetDataInfoFormLS} from "@/utils/utilsset";

export default {
    name: "clienttable",
    props:{
        //这是父组件传入的内容
        parentmsg: {
            type: Object
        },
    },
    data() {
        return {
            tableData1:[],//渲染表格数据
            headerDetail1: [],//渲染1号表头
            table1Style:{},//用于控制table1的css，暂时没用
            table2Style:{},//用于控制e-chart的css，暂时没用
            chartStyle:{'height': '18vw','background': 'rgba(255,255,255,0.05)'},//用于控制e-chart的css
            customChart:null,//页面上的chart的对象
            //ul相关
            ulKey:'',
            ulData: [],
            ulLoading: false,
            ulStyle:{
                overflow:'auto',
                height: '220px',
                color:'#ffffff',
                fontSize:'12px',
            },
            //输入框相关
            form:{
                inputData:'',//输入框的内容
            },
            //用于设置echart的参数
            chartOption:{
                tooltip: {},
                //页面的位置布局相关
                grid: {
                    left: '2%',
                    right: '4%',
                    bottom: '6%',
                    top: '18%',
                    containLabel: true
                },
                //图例
                legend: {
                    x: "center",
                    top: 3,
                    textStyle: {
                        //color: "#3ddeff",
                        color: "#ffffff",
                        fontSize: 13,
                    },
                    itemWidth: 15,
                    itemHeight: 15,
                },
                //数据集，由父组件传入
                dataset: {
                    // 提供一份数据。
                    source: null,
                },
                //x轴相关的样式
                xAxis: {
                    type: 'category',
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.15)',
                        },

                    },
                    axisLabel: {
                        show: true,//隐藏底部时间
                        formatter: '{value}',
                        fontSize: 12,
                        margin: 10,
                        interval: 0,
                        rotate: 90,
                        textStyle: {
                            //color: "#3ddeff",
                            color: "#ffffff",
                        },
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                //y轴相关的样式
                yAxis: {
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            //color: "#3ddeff",
                            color: "#ffffff",
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.15)',
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.15)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                },
                //数据集的展示方法，由父组件传入
                series: null,
                // dataZoom 组件 用于区域缩放，由父组件传入
                dataZoom:null,
                /*dataZoom: [
                    {
                        type: 'slider',
                        show: true, // 设置 dataZoom-inside 组件控制的 x轴
                        // 数据窗口范围的起始和结束百分比  范围: 0 ~ 100
                        start: 0,
                        end: 100,
                        xAxisIndex: [0],
                        filterMode: 'empty',
                        bottom: -25
                    }
                ],*/
            },
            //数据字典相关
            dataCode:'',
            oDataInfo:'',
        };
    },
    created() {
        //echart的样式可由父组件控制
        this.chartStyle=this.parentmsg.tablePara.chartStyle||{'height': '30vh','background': 'rgba(255,255,255,0.05)'};
        //ul的样式可由父组件控制
        this.ulStyle=this.parentmsg.tablePara.ulStyle||{
            overflow:'auto',
            height: '220px',
            color:'#ffffff',
            fontSize:'12px',
        };
        //console.log(this.chartStyle);
        if (this.parentmsg.tablePara.xAxis){
            this.chartOption.xAxis=this.parentmsg.tablePara.xAxis;
        }
        //是否需要展示第一个table
        if(this.parentmsg.tableType==='table') {
            this.initTable(
                this.parentmsg.tablePara.tableData1,
                this.tableData1,
                this.headerDetail1);
        }
        //渲染传入的inputData
        this.form.inputData=this.parentmsg.inputData;
        //是否需要展示ul
        if(this.parentmsg.tableType==='ul') {
            this.initUl(this.parentmsg.tablePara.tableData1,);
        }
        if(this.parentmsg.tablePara.dataInfo){
            //
        }
    },
    mounted() {

        if(this.parentmsg.tableType==='chart')
        {
            this.initChart();
        }
    },
    computed: {
        //加载动画的版本的ul相关
        //用于限制最大加载数量
        ulNoMore () {
            //return false;
            return this.ulData.length>=20;
        },
        //
        ulDisabled () {
            return this.loading || this.ulNoMore
        }
    },
    methods: {
        //初始化table
        initTable(input,tableData,headerData){
            //console.log(this.parentmsg.title);
            for (let i in input) {

                let tableDetail = {};
                for (let j in input[i]){
                    if(Number(i)===0){
                        headerData.push({name:input[i][j],key:'num'+j});
                    } else {
                        tableDetail['num'+j]=input[i][j];
                    }
                }
                if(Number(i)===0)
                    continue;
                tableData.push(tableDetail);
            }
            /*
            console.log('tableData1');
            console.log(JSON.stringify(this.tableData1));
            console.log('headerDetail1');
            console.log(JSON.stringify(this.headerDetail1));*/
        },
        //初始化echart参数
        initChart(){
            let app=this;
            //父组件传入数据
            this.chartOption.dataset.source=this.parentmsg.tablePara.dataSet;
            this.chartOption.series=this.parentmsg.tablePara.series;
            this.chartOption.dataZoom=this.parentmsg.tablePara.dataZoom||null;
            //开始初始化echart
            this.customChart = app.$echarts.init(this.$refs.customChart);
            this.customChart.setOption(this.chartOption);

            window.addEventListener('resize', function () {
                app.customChart.resize();
            })
        },
        //输入框变化的钩子函数
        inputChange(){
            //console.log('输入框变化');
            this.$emit('inputChange', this.form.inputData);
        },
        //初始化table
        initUl(input){
            let tmp=input;
            if(this.parentmsg.tablePara.dataInfo){
                // console.log(input)
                tmp = input.map((item, i, arr)=>{
                    if(i===0){
                        return item
                    }
                    item[this.parentmsg.tablePara.dataInfo.colIndex]=fDataInfoFormatter(this.parentmsg.tablePara.dataInfo.dataCode,
                        item,this.parentmsg.tablePara.dataInfo.colIndex);

                    return item
                })
                // console.log(tmp)
            }
            this.ulData=tmp;
        },
        //动态加载ul
        loadUl () {
            //
            //console.log('loadUl变化');
            this.$emit('loadUl','');//猜测到时候可能因为网络问题会出现一些奇妙现象

            //测试用的自动添加
            /*this.ulData.push(['070012','12','NG','OK',]);
            this.ulData.push(['070012','12','NG','OK',]);*/


            //出现加载动画的版本，我感觉有点bug不好用
            /*this.ulLoading = true
            setTimeout(() => {
                this.ulData.push(['070012','12','NG','OK',]);
                this.ulData.push(['070012','12','NG','OK',]);
                this.ulLoading = false
            }, 2000);*/
        },

    },
    watch: {
        //监听父组件的消息
        parentmsg:{
            handler: function (newV, oldV) {
                // oldV  parentmsg旧值
                // newV parentmsg新值
                // if(newV.tablePara.dataZoom)
                //     console.log(newV);

                //根据异步更新的数据进行更新
                if(this.customChart!=null) {
                    //更新echart
                    this.chartOption.dataset.source=newV.tablePara.dataSet;
                    this.chartOption.series=newV.tablePara.series;
                    this.chartOption.dataZoom=newV.tablePara.dataZoom||null;
                    //console.log(this.chartOption);
                    //此时不需要初始化echart了
                    this.customChart.setOption(this.chartOption);

                }
                else if(this.parentmsg.tableType==='table'){
                    //更新1号table
                    this.tableData1=[];
                    this.headerDetail1=[];
                    this.initTable(
                        this.parentmsg.tablePara.tableData1,
                        this.tableData1,
                        this.headerDetail1);
                }else if(this.parentmsg.tableType==='ul'){
                    this.initUl(this.parentmsg.tablePara.tableData1,);
                }
                this.form.inputData=this.parentmsg.inputData;
            },
            deep:true,//深度监听
            //immediate:true,//立刻执行
        }
    }
}
</script>

<style scoped>
.client-title-bar{
    margin-top: 10px;
    margin-bottom: 17px;
    padding-left: 3px;
    /*background: linear-gradient(180deg,#34c2c9,#34176d);*/
    width: 100%;
    height: 2.5vh;
    min-height: 2.5vh;
    text-align: left;
    font-size: 14px;
    color: #E9E7E7;
}
.client-table{
    padding: 3px 5px;
}
.client-table .el-form-item{
    margin-bottom: 0;
}
.client-ul-row{

}
.client-ul-col{
    padding-top: 6px;
    min-height: 30px;
    max-height: 30px;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,0.12);
    border-right: 1px solid rgba(255,255,255,0.07);
    background-color: rgba(255, 255, 255, 0.05);
    overflow: hidden;
}
th{
    height: 4vh;
}
tr td{
    height: 3.5vh;
}
</style>
