<template>
    <!--   2022-10-10：增加管理员主页隐藏功能，仅管理员账户可供查看。后续会开发的客户主页。   根据权限加载首页页面 -->
  <div class="home-container" :style="bg" ref="home-container">
<!--      <iframe v-if="client===false" :src='src' frameborder='0' style="width:100%;height:100%;"></iframe>-->
      <el-row v-if="client===false" >
          <el-col :span="24" >
            <!--   顶部的header  -->
              <el-row >
                  <el-col :span="24" >
                      <div class="top-bar">
                          <el-row>
                              <el-col :span="6" :offset="9">
                                  <div class="title-bar">
                                      阴极板全生命周期管理系统
                                  </div>
                              </el-col>
                              <!--     日期   -->
                              <el-col :span="5" :offset="0">
                                  <div class="title-date">
                                      <el-row>
                                          <el-col :span="16" :offset="3">
<!--                                              <el-date-picker
                                                  v-model="selectedDate"
                                                  @change="changeDate()"

                                                  size="mini"

                                                  type="datetimerange"
                                                  range-separator="至"
                                                  start-placeholder="开始日期"
                                                  end-placeholder="结束日期"

                                                  value-format="yyyy-MM-dd HH:mm:ss"
                                              >
                                              </el-date-picker>-->
                                              <el-date-picker
                                                  v-model="selectedDate"
                                                  size="mini"
                                                  type="date"
                                                  placeholder="请选择日期"
                                                  value-format="yyyy-MM-dd"
                                              >
                                              </el-date-picker>
                                          </el-col>

                                          <el-col :span="4" :offset="0">
                                              <el-button size="mini" type="info" @click="resetDate()">重置日期</el-button>

                                          </el-col>
                                      </el-row>

                                  </div>
                              </el-col>
                              <!--     时间         -->
                              <el-col :span="2" :offset="2">
                                  <div class="title-time">
                                      <p >{{currentTime}}</p>
                                  </div>

                              </el-col>
                          </el-row>

                      </div>
                  </el-col>
              </el-row>

              <!--  line-chart和table的内容区  -->
              <div class="content">
                  <el-row>
                      <el-col :span="6" :offset="0">
                          <el-row>
                              <el-col :span="24" :offset="0">
                                  <div class="top-left-table ">
                                      <datatable :parentmsg="parentMsgArr[0]" />
                                  </div>
                              </el-col>
                          </el-row>
                          <el-row>
                              <el-col :span="24" :offset="0">
                                  <div class="mid-left-table ">
                                      <datatable :parentmsg="parentMsgArr[1]"/>
                                  </div>
                              </el-col>
                          </el-row>
                      </el-col>
                      <!--    百度地图-->
                      <el-col :span="12" :offset="0">
                          <div class="center-map">
                              <baidu-map class="map" :center="map.center" :zoom="map.zoom" :key="key"
                                         @ready="handler">
                                  <!--缩放-->
                                  <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
                                  <!--定位-->
                                  <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
                                  <!--点-->
<!--                                  <bm-marker :position="map.center" :dragging="map.dragging" animation="BMAP_ANIMATION_DROP" @dragend="dragend">
                                      &lt;!&ndash;提示信息&ndash;&gt;
                                      &lt;!&ndash; <bm-info-window :show="false"></bm-info-window> &ndash;&gt;
                                  </bm-marker>-->
                              </baidu-map>
                          </div>
                      </el-col>
                      <el-col :span="6" :offset="0">
                          <el-row>
                              <el-col :span="24" :offset="0">
                                  <div class="top-right-table ">
                                      <datatable :parentmsg="parentMsgArr[2]"/>
                                  </div>
                              </el-col>
                          </el-row>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="18" :offset="0">
                          <div class="footer-left-table ">
                              <datatable :parentmsg="parentMsgArr[3]"/>
                          </div>
                      </el-col>
                      <el-col :span="6" :offset="0">
                          <div class="footer-right-table ">
                              <datatable :parentmsg="parentMsgArr[4]"/>
                          </div>
                      </el-col>
                  </el-row>
              </div>


              <div class="fullscreen" @click="switchFullscreen(`home-container`)">
              </div>
          </el-col>
      </el-row>
<!--      <el-row v-if="client" type="flex" justify="center" align="middle">
          <el-col :span="10" >
&lt;!&ndash;    parentmsg（必填）为输入参数；inputChange（选填）为输入框的钩子函数      &ndash;&gt;
              <clienttable :parentmsg="clientMsgArr[0]" @inputChange="" />
              <clienttable :parentmsg="clientMsgArr[1]" @inputChange="" />
          </el-col>
      </el-row>-->
      <el-row v-if="client" >
          <el-col :span="24">
              <ClientPage></ClientPage>
          </el-col>
      </el-row>
  </div>
</template>

<script>

import {BaiduMap, BmAutoComplete, BmControl, BmLocalSearch, BmMarker, BmNavigation, BmView} from "vue-baidu-map";
import datatable from '../components/dataTable.vue';
import Clienttable from "@/components/clientTable.vue";
import Tabstable from "@/components/tabsTable.vue";
import ClientPage from "../views/clientManage/Client.vue"
import {sanyouAxios} from "@/api";

export default {
  name: "home",
    //地图组件
    components: {
        Tabstable,
        Clienttable,
        BaiduMap,
        BmControl,
        BmView,
        BmAutoComplete,
        BmLocalSearch,
        BmMarker,
        BmNavigation,
        datatable,
        ClientPage
    },
  data() {
    return {
        //从localstorage里面拿的user id
        currentUserId:'',
        //client为客户主页
        client:'',
        //用户选择的时间
        selectedDate:'',

        //判断是否为全屏
        isFullScreen:false,
        //当前时间，动态刷新
        currentTime:'',
        //传入子组件的参数
        parentMsgArr:[
            {
                title:'阴极铜产量',//标题
                tableNum:1,//数据表的数量
                tableType:'chart',//数据表的类型:table,chart
                isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
                //数据表参数
                tablePara:{
                    //控制echart的css样式，主要是是为了控制高度
                    chartStyle:{'height': '22.0vh','background': 'rgba(14,194,255,0.15)'},
                    //数据集   https://echarts.apache.org/handbook/zh/concepts/dataset
                    dataSet:[
                        ["奥炉车间", "双闪车间", "XX车间1", "XX车间2", "XX车间3", "双闪车间4", "XX车间5", "XX车间6",
                            "XX车间7", "双闪车间8", "XX车间9", "XX车间10", "XX车间11", "双闪车间12", "XX车间13", "XX车间14", "XX车间15"],
                        [15, 30, 25, 20, 10, 30, 25, 20, 10, 30, 25, 20, 10, 30, 25, 20, 10],
                    ],
                    //展示的样式   type: 'bar'  'line'
                    series: [
                        {
                            type: 'bar',
                            seriesLayoutBy: 'row',//横向读取数据集
                            barWidth: '30%',
                            itemStyle: {
                                normal: {
                                    color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: 'rgba(0,244,255,1)' // 0% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: 'rgba(0,77,167,1)' // 100% 处的颜色
                                    }], false),
                                    shadowColor: 'rgba(0,160,221,1)',
                                    shadowBlur: 4,
                                }
                            },
                            label: {
                                normal: {
                                    show: true,
                                    lineHeight: 10,
                                    formatter: '{c}',
                                    position: 'top',
                                    textStyle: {
                                        color: '#00D6F9',
                                        fontSize: 12
                                    }

                                }
                            }
                        }
                    ],
                    // dataZoom 组件 用于区域缩放
                    dataZoom: [
                        {
                            type: 'slider',
                            show: true, // 设置 dataZoom-inside 组件控制的 x轴
                            // 数据窗口范围的起始和结束百分比  范围: 0 ~ 100
                            start: 0,
                            end: 20,
                            xAxisIndex: [0],
                            filterMode: 'empty',
                            bottom: -25
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255,255,255,0.15)',
                            },

                        },
                        axisLabel: {
                            show: false,//隐藏底部时间
                            formatter: '{value}',
                            fontSize: 12,
                            margin: 10,
                            interval: 0,
                            rotate: 90,
                            textStyle: {
                                color: '#3ddeff',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                },
            },
            {
                title:'阴极板统计',//标题
                tableNum:1,//数据表的数量
                tableType:'table',//数据表的类型:table,chart
                isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
                //数据表参数
                tablePara:{
                    //本来想实现父组件动态控制子组件的高度，但目前无法实现
                    table1Height:240,
                    //element ui的table默认是纵向加载数据的，
                    // 为了让数据横向加载，这里采用别的方法来排列数据
                    //1号表格的数据
                    tableData1: [
                        [' ','三门三友','铜陵有色金属集团股份有限公司金冠铜业分公司','赤峰云铜有色金属有限公司','赤峰金通铜业有限公司','广西南国铜业有限公司',
                            '浙江江铜富冶和鼎铜业有限公司','广西金川有色金属有限公司','中铜东南铜业有限公司','大冶有色金属有限责任公司',
                            '金隆铜业有限公司','葫芦岛宏跃北方铜业有限责任公司','河南中原黄金冶炼厂有限责任公司','河南豫光金铅股份有限公司',
                            '张家港联合铜业有限公司','青海铜业有限责任公司', '中国十五冶金建设集团有限公司','金川集团铜业有限公司','黑龙江紫金铜业有限公司',
                            '江苏庆峰工程集团有限公司','北京兴源诚经贸发展有限公司','盛屯金属有限公司','河口明成技术贸易有限公司','紫金矿业物流有限公司',],
                        ['总板数','0','10000','0','0','0',
                            '0','0','0','0',
                            '120','0','0','0',
                            '0','0', '0','0','0',
                            '0','0','5641','0','0',],
                        ['在线板数','0','0','0','0','0',
                            '0','0','0','0',
                            '0','0','0','0',
                            '0','0', '0','0','0',
                            '0','0','0','0','0',],
                        ['维修板数','0','0','0','0','0',
                            '0','0','0','0',
                            '0','0','0','0',
                            '0','0', '0','0','0',
                            '0','0','0','0','0',],
                        ['备用板数','0','10000','0','0','0',
                            '0','0','0','0',
                            '120','0','0','0',
                            '0','0', '0','0','0',
                            '0','0','5641','0','0',],
                        ['截止到今日','0','0','0','0','0',
                            '0','0','0','0',
                            '0','0','0','0',
                            '0','0', '0','0','0',
                            '0','0','0','0','0',],
                        ['备用板数','-','-','-','-','-',
                            '-','-','-','-',
                            '-','-','-','-',
                            '-','-', '-','-','-',
                            '-','-','-','-','-',],
                        ['备用板数','-','-','-','-','-',
                            '-','-','-','-',
                            '-','-','-','-',
                            '-','-', '-','-','-',
                            '-','-','-','-','-',],
                    ],
                },
            },
            {
                title:'机组剥片速度',//标题
                tableNum:2,//数据表的数量
                tableType:'chart',//数据表的类型:table,chart
                isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
                //数据表参数
                tablePara:{
                    //控制echart的css样式，主要是是为了控制高度
                    chartStyle:{'height': '21vh','background': 'rgba(14,194,255,0.15)'},
                    //数据集    https://echarts.apache.org/handbook/zh/concepts/dataset
                    dataSet:[
                        ['data','7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00',
                            '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '0:00',
                            '1:00', '2:00', '3:00', '4:00', '5:00', '6:00'],
                        ["奥炉车间",0, 250, 500, 550, 330, 485, 456, 248, 487, 125, 400, 300, 550, 400, 500, 550, 0, 0, 0, 0, 0, 0, 0, 0],
                        ["双闪车间", 0, 248, 487, 125, 400, 300, 550, 400, 250, 500, 550, 330, 485, 456, 500, 550, 0, 0, 0, 0, 0, 0, 0, 0],
                        ["XX车间1",0, 200, 300, 330, 400, 500, 550, 400, 250, 300, 580, 400, 500, 600, 500, 300, 0, 0, 0, 0, 0, 0, 0, 0],
                        ["XX车间2",0, 300, 550, 400, 250, 500, 550, 200, 300, 330, 400, 500, 550, 200, 300, 330, 0, 0, 0, 0, 0, 0, 0, 0],
                        ["XX车间3",0, 580, 400, 500, 600, 500, 300, 200, 300, 330, 400, 500, 550, 400, 250, 300, 0, 0, 0, 0, 0, 0, 0, 0],
                    ],
                    //展示的样式   type: 'bar'  'line'
                    series: [
                        { type: 'line', seriesLayoutBy: 'row'},//横向读取数据集
                        { type: 'line',seriesLayoutBy: 'row' },
                        { type: 'line',seriesLayoutBy: 'row' },
                        { type: 'line',seriesLayoutBy: 'row' },
                        { type: 'line',seriesLayoutBy: 'row' },
                    ],
                    //本来想实现父组件动态控制子组件的高度，但目前无法实现
                    table2Height:30,
                    //element ui的table默认是纵向加载数据的，
                    // 为了让数据横向加载，这里采用别的方法来排列数据
                    //2号表格的数据
                    tableData2:[
                        ['剥片速度','奥炉车间','双闪车间','XX车间','XX车间','XX车间'],
                        ['7:00','0','0','0','0','0'],
                        ['8:00','250','300','200','230','240'],
                        ['9:00','500','500','400','350','420'],
                        ['10:00','550','600','500','245','430'],
                        ['11:00','330','340','500','348','320'],
                        ['12:00','485','453','427','420','210'],
                        ['13:00','456','542','538','436','330'],
                        ['14:00','248','472','330','350','410'],
                        ['15:00','487','496','500','220','140'],
                        ['16:00','125','487','451','310','340'],
                        ['17:00','400','357','541','234','340'],
                        ['18:00','572','570','400','340','330'],
                        ['19:00','210','600','200','450','450'],
                        ['20:00','0','500','300','100','20'],
                        ['21:00','0','0','0','0','0'],
                        ['22:00','0','0','0','0','0'],
                        ['23:00','0','0','0','0','0'],
                        ['24:00','0','0','0','0','0'],
                        ['1:00','0','0','0','0','0'],
                        ['2:00','0','0','0','0','0'],
                        ['3:00','0','0','0','0','0'],
                        ['4:00','0','0','0','0','0'],
                        ['5:00','0','0','0','0','0'],
                        ['6:00','0','0','0','0','0'],
                    ],
                },},
            {
                title:'动态检测数据',//标题
                tableNum:1,//数据表的数量
                tableType:'chart',//数据表的类型:table,chart
                isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
                //数据表参数
                tablePara:{
                    //控制echart的css样式，主要是是为了控制高度
                    chartStyle:{'height': '22.0vh','background': 'rgba(14,194,255,0.15)'},
                    //数据集    https://echarts.apache.org/handbook/zh/concepts/dataset
                    dataSet:[
                        ['data',"某车间", ],
                        ['健康',0, ],
                        ['亚健康',0, ],
                        ['不健康',0, ]],
                    //展示的样式   type: 'bar'  'line'
                   /* series: [
                        { type: 'bar', seriesLayoutBy: 'row'},//横向读取数据集
                        { type: 'bar',seriesLayoutBy: 'row' },
                        { type: 'bar',seriesLayoutBy: 'row' },
                    ],*/
                    series: [
                        {
                            type: 'bar',
                            seriesLayoutBy: 'row',//横向读取数据集
                            barWidth: '10%',
                            itemStyle: {
                                color: "#4CAE85",
                                // normal: {
                                //     color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                //         offset: 0,
                                //         color: 'rgba(0,244,255,1)' // 0% 处的颜色
                                //     }, {
                                //         offset: 1,
                                //         color: 'rgba(0,77,167,1)' // 100% 处的颜色
                                //     }], false),
                                //     shadowColor: 'rgba(0,160,221,1)',
                                //     shadowBlur: 4,
                                // }
                            },
                            label: {
                                normal: {
                                    show: true,
                                    lineHeight: 10,
                                    formatter: '{c}',
                                    position: 'top',
                                    textStyle: {
                                        color: '#00D6F9',
                                        fontSize: 12
                                    }

                                }
                            }
                        },
                        {
                            type: 'bar',
                            seriesLayoutBy: 'row',//横向读取数据集
                            barWidth: '10%',
                            itemStyle: {
                                color: "#FFAD00",//'#4CAE85','#FFAD00','#FF6356'
                            },
                            label: {
                                normal: {
                                    show: false,
                                    lineHeight: 10,
                                    formatter: '{c}',
                                    position: 'top',
                                    textStyle: {
                                        color: '#00D6F9',
                                        fontSize: 12
                                    }

                                }
                            }
                        },
                        {
                            type: 'bar',
                            seriesLayoutBy: 'row',//横向读取数据集
                            barWidth: '10%',
                            itemStyle: {
                                color: "#FF6356",//'#4CAE85','#FFAD00','#FF6356'
                            },
                            label: {
                                normal: {
                                    show: false,
                                    lineHeight: 10,
                                    formatter: '{c}',
                                    position: 'top',
                                    textStyle: {
                                        color: '#00D6F9',
                                        fontSize: 12
                                    }

                                }
                            }
                        }
                    ],
                    // dataZoom 组件 用于区域缩放
                    dataZoom: [
                        {
                            type: 'slider',
                            show: true, // 设置 dataZoom-inside 组件控制的 x轴
                            // 数据窗口范围的起始和结束百分比  范围: 0 ~ 100
                            start: 0,
                            end: 20,
                            xAxisIndex: [0],
                            filterMode: 'empty',
                            bottom: -25
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255,255,255,0.15)',
                            },

                        },
                        axisLabel: {
                            show: false,//隐藏底部时间
                            formatter: '{value}',
                            fontSize: 12,
                            margin: 10,
                            interval: 0,
                            rotate: 90,
                            textStyle: {
                                color: '#3ddeff',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                },
            },
            {
                title:'阴极铜表面缺陷数据',//标题
                tableNum:1,//数据表的数量
                tableType:'table',//数据表的类型:table,chart
                isRefreshed:false,//父组件传入的参数，用于处理处理异步更新
                //数据表参数
                tablePara:{
                    //本来想实现父组件动态控制子组件的高度，但目前无法实现
                    table1Height:235,
                    //element ui的table默认是纵向加载数据的，
                    // 为了让数据横向加载，这里采用别的方法来排列数据
                    //1号表格的数据
                    tableData1:[
                        ['序号','车间名称','铜外观YES/NG'],
                        ['1','奥炉车间','10'],
                        ['2','双闪车间','50'],
                        ['3','XX车间','30'],
                        ['4','XX车间','20'],
                        ['5','XX车间','35'],
                        ['6','XX车间','0'],
                        ['7','XX车间','0'],
                        ['8','XX车间','35'],
                        ['9','XX车间','0'],
                        ['10','XX车间','0'],
                    ],
                },
            },
        ],
        //客户主页的相关内容（需要移入子页面）

        //用于控制html的css样式
        bg: {
            backgroundRepeat:'no-repeat',
            backgroundSize:'100%',
        },
        //地图
        key:1,
        map:{
            center: {lng: 117.782393, lat: 30.933811},
            zoom: 5,
            show: true,
            dragging: true,
            //address:"杭州电子科技大学",
            equipId:"",
        },
        src:'',
    };
  },
    created() {
        this.currentUserId = localStorage.getItem('id');
      //增加管理员主页隐藏功能，仅管理员账户可供查看。后续会开发的客户主页。
      //检查localStorage的flag
        let oMenuList = JSON.parse(localStorage.getItem('menu'));
        //console.log(oMenuList);
        if (oMenuList[0].flag === 'root') {
            //将this.client = true;注释修改后，就可以进入客户主页
            this.client = false;
            //this.client = true;
            //客户端看到的背景
            this.bg.backgroundImage = 'url(' + require('../assets/imgs/bg.jpg') + ')';
            //给右上角的日期选择器赋值
            this.resetDate();

            //发起请求
            //this.requestAll();
        }
        else
        {
            //将注释this.client = false;修改后，就可以进入客户主页
            this.client = true;
            //this.client = false;
            //客户端看到的背景
            //this.bg.backgroundImage = 'url(' + require('../assets/imgs/home.png') + ')';
            this.bg.backgroundImage = 'url(' + require('../assets/imgs/bgClient.png') + ')';
        }
    },
    mounted(){
        if(!this.client){
            setInterval(this.refreshTime, 1000);
        }
  },
  methods: {
      //用于刷新右上角的时钟
      refreshTime(){
          let date =  new Date();
          this.currentTime=this.appendZero (date.getHours())+':'+this.appendZero (date.getMinutes())+':'+this.appendZero (date.getSeconds());
      },
      //用于给时间字符串补0
      appendZero (obj) {
          if (obj < 10) return "0" + obj; else return obj;
      },
      //百度地图初始化
      handler({BMap, map}){
          let me = this;
          // console.log(BMap, map)
          // 鼠标缩放
          map.enableScrollWheelZoom(true);
          // 点击事件获取经纬度
          /*map.addEventListener('click', function (e) {
              // console.log(e.point.lng, e.point.lat)
          })*/
          //加载地图点位
          this.loadMapMarker(map);
      },
      //加载地图标记
      loadMapMarker(map){
          //死数据
          /*this.createMarker(map,'金隆铜业有限公司','',117.782393,30.933811,120,0);
          this.createMarker(map,'盛屯金属有限公司','',118.237792,24.650749,5641,0);
          this.createMarker(map,'广西南国铜业有限公司','',107.816722,22.540277,0,0);
          this.createMarker(map,'赤峰金通铜业有限公司','',119.139474,42.258663,0,0);
          this.createMarker(map,'张家港联合铜业有限公司','',120.707229,31.95687,0,0);
          this.createMarker(map,'青海铜业有限公司','',101.504264,36.598169,0,0);
          this.createMarker(map,'黑龙江紫金铜业有限公司','',123.585691,47.189473,0,0);
          this.createMarker(map,'江苏庆峰工程集团有限公司','',119.447043,32.471629,0,0);*/
          //动态请求数据
          sanyouAxios({
              headers:{
                  //加了header会报错？
                  //userId:this.currentUserId
              },
              method: 'GET',
              url: '/factory/getFactoryAndProductCount',
          }).then(res => {
              if(res.data.status === 200){
                  let data=res.data.data;
                  // console.log(data);
                  for(let i in data){
                      //console.log(i);
                      //console.log(data[i]);
                      this.createMarkerBySubFactory(map,data[i]);
                  }
              }else{
                  this.$message({
                      type: 'warning',
                      duration: 2000,
                      message: res.data.msg
                  });
              }
          });
      },
      //根据车间进行标点
      createMarkerBySubFactory(map, data) {
          /*示例：*/
          //console.log('------------------------');
          //console.log((data));

          /*factoryLat:车间纬度
          factoryLng:车间经度
          * */
          let factory_pos = {factory_lat: data['factoryLat'], factory_lng: data['factoryLng']};
          //console.log("维度" + data['factoryLat'])
          let subFactoryStr = '';
          if (data['subFactoryName'] == null || data['subFactoryName'].length === 0) {//如果没有车间
              //不做任何事
          } else {
              this.createMarker(map, data['factoryName'],data['subFactoryName'],
                  data['factoryLng'], data['factoryLat'],
                  data['num'], data['uncodeNum']);
          }

          if (subFactoryStr.length > 30) {
              //名字过长可能要进行一些处理
              //目前先暂时这样吧
              //console.log(subFactoryStr);
              //subFactoryStr='<div style="font-size: 6px">'+subFactoryStr+'</div>';
          }

      },
      //创建地图标记
      createMarker(map,com,subFactory,lng,lat,num,uncodeNum){
          //console.log("sub"+subFactory)
          //增加判空条件
          if(!subFactory){
              subFactory='-';
          }
          if(!num){
              num=0;
          }
          if(!uncodeNum){
              uncodeNum=0;
          }
          //默认位置在杭电//目前如果没有点位就不显示
          if(!lng)
          {
              //lng= 120.349799;
              return;
          }
          if(!lat)
          {
              //lat= 30.320579;
              return;
          }
          //console.log(JSON.stringify({com:com,subFactory:subFactory,lng:lng,lat:lat,num:num,uncodeNum:uncodeNum,}));
          let point = new BMap.Point(lng,lat);
          let marker = new BMap.Marker(point);
          map.addOverlay(marker);
          let opts = {
              width: 1,
              height: 1,
              title: com+'<br>'+subFactory,
          };

          //地图中冶炼厂的内容
          let infoWindow = new BMap.InfoWindow('合计阴极板数：'+(Number(num)+Number(uncodeNum))+'<br>' +
              '有编码：'+num+'  无编码：'+uncodeNum, opts);
          marker.addEventListener('click', function () {
              map.openInfoWindow(infoWindow, point); // 开启信息窗口
          });
      },
      //重新请求数据，渲染所有页面
      requestAll(inputDate){

          let start=this.selectedDate+' 00:00:00';
          let end=this.selectedDate+' 23:59:59';
          //阴极铜产量
          sanyouAxios({
              method:'GET',
              url:'/admin/copperProduction?userId='+this.currentUserId
          }).then(res=>{
              if (res.data.status===200){
                  let data=res.data.data;
                  //console.log('阴极铜产量:'+data);
                  let dataSet=[
                      [],
                      []
                  ]
                  for (let i in data){
                      dataSet[0].push(data[i]['name']);
                      dataSet[1].push(data[i]['count']);
                  }
                  this.parentMsgArr[0].tablePara.dataSet=dataSet;
                  this.$set(this.parentMsgArr[0],'isRefreshed',!this.parentMsgArr[0].isRefreshed);

              }else{
                  this.$message({
                      type: 'warning',
                      duration: 2000,
                      message: res.data.msg
                  });
              }
          })
          //阴极板统计
          sanyouAxios({
              method: 'GET',
              //url: '/product/getAllProductCounts?userId='+'2106267426FB76RP'+'&startTime='+start +'&endTime='+end ,
              url: '/product/getAllProductCounts?userId='+this.currentUserId +'&startTime='+start +'&endTime='+end,
          },this.currentUserId).then(res => {
              if(res.data.status === 200){
                  let data=res.data.data;
                  //console.log(data);
                  let tableData=[
                      ['',],
                      ['总板数',],
                      ['在线板数',],
                      ['维修板数',],
                      ['备用板数',],
                      ['截止到今日',],
                      ['备用板数',],
                      ['备用板数',],
                  ];
                  for(let i in data){
                      //console.log(JSON.stringify(data[i]));

                      //{"offline":0,"scrap":0,"maintain":0,"factoryName":"葫芦岛宏跃北方铜业有限责任公司","online":0,"sum":0}
                      tableData[0].push(data[i]['factoryName']);//工厂名
                      tableData[1].push(data[i]['sum']);//总板数
                      tableData[2].push(data[i]['online']);//在线板数
                      tableData[3].push(data[i]['maintain']);//维修板数
                      tableData[4].push(data[i]['offline']);//备用板数
                      tableData[5].push('0');//截止到今日
                      tableData[6].push(data[i]['offline']);
                      tableData[7].push(data[i]['offline']);
                      //tableData[8].push(data[i]['scrap']);//报废板数
                  }
                  this.parentMsgArr[1].tablePara.tableData1=tableData;
                  //console.log(this.parentMsgArr[1].tablePara.tableData1);
                  //为了解决vue的watch监听，需要使用这个方法this.$set(),详见https://wenku.baidu.com/view/0b92122a0440be1e650e52ea551810a6f524c8ea.html
                  this.$set(this.parentMsgArr[1],'isRefreshed',!this.parentMsgArr[1].isRefreshed);
              }else{
                  this.$message({
                      type: 'warning',
                      duration: 2000,
                      message: res.data.msg
                  });
              }
          });
          //机组剥片速度
          sanyouAxios({
              method:"get",
              url:'admin/strippingSpeed?userId='+this.currentUserId
          },this.currentUserId).then(res=>{
              if (res.data.status===200){
                  let data=res.data.data;
                  //console.log(data)
                  let dataSet=[
                      ['data',],
                  ];
                  let tableData=[
                      ['剥片速度']
                  ];
                  let tableRowData=[];

                  for (let i in data[0]['strippingSpeedList']){
                      dataSet[0].push(data[0]['strippingSpeedList'][i]['time']);
                      let rowTime=[data[0]['strippingSpeedList'][i]['time']];
                      for (let j in data){
                          rowTime.push(data[j]['strippingSpeedList'][i]['speed'])
                      }
                      tableRowData.push(rowTime)
                  }


                  for (let i in data){
                      //dataSet数据拼接
                      let rowData=[data[i]['subFactoryName']];

                      for (let j in data[i]['strippingSpeedList']){
                          rowData.push(data[i]['strippingSpeedList'][j]['speed']);

                      }
                      //tableData数据拼接
                      //表头车间
                      tableData[0].push(data[i]['subFactoryName']);


                      dataSet.push(rowData);
                      // console.log(tableData)

                  }
                  tableData=tableData.concat(tableRowData)
                  //console.log(dataSet)
                  this.parentMsgArr[2].tablePara.dataSet=dataSet;
                  this.parentMsgArr[2].tablePara.tableData2=tableData;

                  this.$set(this.parentMsgArr[2],'isRefreshed',!this.parentMsgArr[2].isRefreshed);

              }else{
                  this.$message({
                      type: 'warning',
                      duration: 2000,
                      message: res.data.msg
                  });
              }
          })
          //动态检测数据的请求
          sanyouAxios({
              method: 'GET',
              //url: '/industryData/getAllLBarChart?userId='+this.currentUserId,

              url: '/industryData/factoryHealthCount?startTime='+start+'&endTime='+end ,

          }).then(res => {
              if(res.data.status === 200){
                  let data=res.data.data;
                  // console.log(data)
                  let dataSet=[
                      ['',],
                      ['健康',],
                      ['亚健康',],
                      ['不健康',],
                  ]


                  for (let i in data){
                      dataSet[0].push((data[i].subFactoryName));
                      dataSet[1].push((data[i].healthValue));
                      dataSet[2].push((data[i].subHealthValue));
                      dataSet[3].push((data[i].unHealthValue));

                  }
                  this.parentMsgArr[3].tablePara.dataSet=dataSet
                  // console.log(dataSet);
                  /*this.parentMsgArr[3].tablePara.dataSet[0]=[' '].concat(data.data.factoryNames);
                  this.parentMsgArr[3].tablePara.dataSet[1]=['健康'].concat(data.data.healthValues);
                  this.parentMsgArr[3].tablePara.dataSet[2]=['亚健康'].concat(data.data.subHealthValues);
                  this.parentMsgArr[3].tablePara.dataSet[3]=['不健康'].concat(data.data.unHealthValues);*/
                  //为了解决vue的watch监听，需要使用这个方法this.$set(),详见https://wenku.baidu.com/view/0b92122a0440be1e650e52ea551810a6f524c8ea.html
                  this.$set(this.parentMsgArr[3],'isRefreshed',!this.parentMsgArr[3].isRefreshed);
              }else{
                  this.$message({
                      type: 'warning',
                      duration: 2000,
                      message: res.data.msg
                  });
              }
          });

          //阴极铜表面缺陷数据
          sanyouAxios({
              method:'GET',
              url:'admin/copperDefect?userId='+this.currentUserId
          }).then(res=>{
              if (res.data.status===200){
                  let data=res.data.data;
                  let tableData=[
                      ['序号','车间名称','铜外观OK/NG'],
                  ]
                  for (let i in data) {
                      let rowData = [i, data[i]['name'], data[i]['countOK'] + '/' + data[i]['countNG']];
                      tableData.push(rowData)
                  }
                  this.parentMsgArr[4].tablePara.tableData1=tableData;
                  this.$set(this.parentMsgArr[4],'isRefreshed',!this.parentMsgArr[4].isRefreshed);



              }else{
                  this.$message({
                      type: 'warning',
                      duration: 2000,
                      message: res.data.msg
                  });

              }
          });
      },
      //当日期选择器变化时
      changeDate(){
          //console.log(this.selectedDate);
          //当前需求不明确，全部重新请求
          this.requestAll();
      },
      //重置日期选择器
      resetDate(){
          let today =  new Date();
          //let nextday =  new Date();
          //nextday.setDate(today.getDate()-1);
          this.selectedDate=today.getFullYear()+'-'+this.appendZero(Number(today.getMonth()+1))+'-'+this.appendZero(Number(today.getDate()));
          //this.selectedDate[1]=nextday.getFullYear()+'-'+this.appendZero(nextday.getMonth())+'-'+this.appendZero(nextday.getDay())+' 00:00:00';
      },
      //切换全屏
      switchFullscreen(x){
          if(this.isFullScreen){
              this.exitFullscreen();
          }
          else {
              this.fullScreen(x);
          }
          this.isFullScreen=!this.isFullScreen;
      },
      //全屏
      fullScreen(x) {
          let element = this.$refs[x];
          if (element.requestFullscreen) {
              element.requestFullscreen();
          } else if (element.msRequestFullscreen) {
              element.msRequestFullscreen();
          } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) {
              element.webkitRequestFullscreen();
          }
      },
      //退出全屏
      exitFullscreen() {
          if (document.exitFullscreen) {
              document.exitFullscreen();
          } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
          } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
          }
      },
      testLoad(){
          console.log("测试")
      }
  },
    watch:{
      //监听selectedDate值的变化
        selectedDate:{
            handler: function (newV, oldV) {
                this.selectedDate=newV;
                this.changeDate()
            },
            //immediate:true,//立刻执行
        }
    }
};
</script>

<style>

.home-container{
  width: 100%;
  height: 100%;
}
.welcome-text{
    margin-top: 30%;
    font-size: 60px;
    /*color: #409EFF;*/
    color: #FDC2A2;
    text-shadow: 1px 1px #56618F;
}
.top-bar{
    margin-top: 2.5vh;
    margin-left: 6.5vh;
    margin-right: 4vh;
    background: url(../assets/imgs/bg-topbar.png) no-repeat;
    background-size: 100%;
    min-height: 7.0vh;
}
.content{
    margin-left: 5.0vh;
    margin-right: 2vh;
}
.title-bar{
    margin-top: -1.6vh;
    background: linear-gradient(180deg,#34c2c9,#34176d);
    min-height: 4vh;
    text-align: center;
    font-size: 3.0vh;
    color: #E9E7E7;
}
.title-date{
    margin-top: 0.8vh;
    min-height: 2.8vh;
}
.title-time{
    margin-top: -0.6vh;
    text-align: center;
    min-height: 2.3vh;
    font-size: 1.6vh;
    color: #E9E7E7;
}
.fullscreen{
    position: absolute;
    display: block;
    right: 1.5vw;
    top: 2vh;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background: url(../assets/imgs/icon-fullscreen.png) no-repeat center center;
    background-size: 100% 100%;
    opacity: 0.8;
}
.data-table {
    margin-top: -2.8vh;
    /*padding-top: 1.5vh;*/
    padding-left: 5px;
    padding-right: 5px;
    /*background: url(../assets/imgs/bg-title.png) no-repeat left top;*/
}
.center-map{
    margin-top: -2.8vh;
    min-height: 60vh;
}
.top-left-table {
    min-height: 28.8vh;
}
.mid-left-table {
    min-height: 32vh;
}
.top-right-table {
    height: 56vh;
    min-height: 50vh;
}
.mid-right-table {
    min-height: 30vh;
}
.footer-left-table {
    min-height: 31vh;
}
.footer-right-table {
    margin-top: -4vh;
    min-height: 20vh;
}
/*百度地图相关*/
.map {
    width: 100%;
    height: 59vh;
}
.map >>> .BMap_cpyCtrl {
    display: none !important;
}
.map >>> .anchorBL {
    display: none !important;
}
/*box-sizing: content-box; width: 220px; height: 86px; position: absolute; left: 16px; top: 6px; z-index: 10; overflow: hidden;*/
.BMap_bubble_title,.BMap_bubble_content{
    font-size: 8px;
    line-height: 15px !important;
}
/*改写element-ui的css*/
/*.home-container {
    width: 100%;
    height: calc(100% - 88px);
}*/
/*管理员页面的data-table*/
.data-table .el-table, .el-table__expanded-cell {
    background-color: rgba(0,0,0,0);
}
.data-table .el-table th, .el-table tr {
    background-color: rgba(0,0,0,0);
}
.data-table .el-table td, .el-table th.is-leaf {
    border-bottom-color: rgba(255, 255, 255, 0.05);
}
.data-table .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right-color: rgba(255, 255, 255, 0.05);
}
.data-table .el-table--border, .el-table--group {
    border-top-color: rgba(255, 255, 255, 0.05);
    border-right-color: rgba(255, 255, 255, 0.05);
    border-bottom-color: rgba(255, 255, 255, 0.05);
    border-left-color: rgba(255, 255, 255, 0.05);
}
.data-table .el-table--border::after, .el-table--group::after {
    top: 0;
    right: 0;
    width: 0;
    /*height: 100%;*/
}
.data-table .client-table .el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
}
/*客户页面的client-table*/
.client-table .el-table, .el-table__expanded-cell {
    background-color: rgba(0,0,0,0);
}
.client-table .el-table th, .el-table tr {
    background-color: rgba(0,0,0,0);
}
.client-table .el-table td, .el-table th.is-leaf {
    border-bottom-color: rgba(255, 255, 255, 0.05);
}
.client-table .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right-color: rgba(255, 255, 255, 0.05);
}
.client-table .el-table--border, .el-table--group {
    border-top-color: rgba(255, 255, 255, 0.05);
    border-right-color: rgba(255, 255, 255, 0.05);
    border-bottom-color: rgba(255, 255, 255, 0.05);
    border-left-color: rgba(255, 255, 255, 0.05);
}
.client-table .el-table--border::after, .el-table--group::after {
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
}
.client-table .el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
}
.client-table .el-tabs__item {
    color: #ffffff;
}
.client-table .el-tabs__item.is-active {
    color: #409EFF;
}
.client-table .el-tabs__nav-wrap::after {
    background-color: rgba(255,255,255,0);
}
.client-table .el-tabs__content {
    margin-top: -8px;
}
/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar
{
    width:8px;
    height:8px;
    background-color:#F5F5F5;
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track
{
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
    border-radius:10px;
    background-color:#F5F5F5;
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
    border-radius:2px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
    background-color:#555;
}
</style>
